<template>
  <div class="tomorrow">
    <router-view v-if="category"></router-view>
    <main-tomorrow v-else></main-tomorrow>
  </div>
</template>

<script>
import MainTomorrow from '@/components/MainTomorrow';
export default {
  name:'Tomorrow',
  data(){
    return {
      category:this.$route.params.tocategory,
    };
  },
  components: {
    MainTomorrow,
  }
}
</script>

<style lang="css">
body{
  overflow-y: visible;
}
</style>
